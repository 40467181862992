var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","content-class":"my-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.dialogTitle)?_c('v-toolbar',{attrs:{"color":"lighten-4","flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":"","color":_vm.iconColor},domProps:{"textContent":_vm._s(_vm.tracklistIcon)}}),_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-sheet',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.isSpotifyTracklist)?_c('v-menu',{attrs:{"rounded":"b-xl","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","outlined":"","small":"","color":"primary","disabled":_vm.isAddSelectedTracksToPlaylistMenuDisabled}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add "+_vm._s(_vm.selectedTracks.length)+" tracks to playlist")])])]}}],null,false,4203730441)},[_c('v-list',[_c('span',{staticClass:"px-4 py-2 d-flex text-subtitle-1 text--darken-2"},[_vm._v("Imported playlists:")]),_vm._l((_vm.spotifyPlaylistsToAddListOfTracks),function(spotifyPlaylist){return _c('v-list-item',{key:spotifyPlaylist.id,attrs:{"link":"","disabled":_vm.selectedTracksToAddToPlaylist(spotifyPlaylist).length === 0},on:{"click":function($event){return _vm.addSelectedTracksToSpotifyPlaylist(spotifyPlaylist)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":_vm.spotifyIconColor}},[_vm._v(_vm._s(_vm.spotifyIcon))]),_vm._v(" "+_vm._s(spotifyPlaylist.name)+" (+"+_vm._s(_vm.selectedTracksToAddToPlaylist(spotifyPlaylist).length)+") ")],1)],1)})],2)],1):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","outlined":"","small":""},on:{"click":function($event){_vm.showAllTracks = !_vm.showAllTracks}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.showAllTracks ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.expansionTooltipContent))])]),_c('v-spacer'),_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"dense":"","label":"Search","clear-icon":"mdi-close","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.tracklistSearchInput),callback:function ($$v) {_vm.tracklistSearchInput=$$v},expression:"tracklistSearchInput"}})],1)]),_c('v-sheet',{staticClass:"px-4 pb-4 text-caption"},[_vm._v(" Displaying "+_vm._s(_vm.itemsPerPage)+" out of "+_vm._s(_vm.totalNumberOfTracks)+" tracks ")]),(_vm.tracklistInDialog)?_c('v-data-table',{key:_vm.componentKey,attrs:{"fixed-header":"","height":"60vh","headers":_vm.headers,"items":_vm.tracklistInDialog.tracks,"items-per-page":_vm.itemsPerPage,"search":_vm.tracklistSearchInput,"page":_vm.page,"show-select":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.artists",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.artists.join(', '))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TracksTableRowActions',{attrs:{"track":item,"spotifyPlaylist":_vm.spotifyPlaylist}})]}}],null,true),model:{value:(_vm.selectedTracks),callback:function ($$v) {_vm.selectedTracks=$$v},expression:"selectedTracks"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }